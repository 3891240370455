import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link} from "react-router-dom";

import clip from "../../assets/clip1.png";
import userIcon from "../../assets/userIcon.png";

import {useNavigate} from 'react-router-dom';
import {apiBaseURL,apiURL,apiKey,baseURL} from './../../constant';
import Alert from '@mui/material/Alert';
import userContext from '../../Store';


//function Login() {  
const Login = () =>{
    
// login code  
const[accessCode, setaccessCode]=useState("");
const {login, error, loader, checkLogin} = useContext(userContext);
// console.log(loader);
// login code

    
useEffect( ()=>{
  window.addEventListener('popstate', (event) => {
    checkLogin(true);
  });
},[] )

 return (   
    <>
    <div className="banner-section"> 
      <Container fluid>
        <Row>  
          <Col md="7"> 
            <h1> Welcome To CUET UG 2025 </h1>
            <center> <img className="animation" src={clip} /> </center>
            <p> CUET Online Portal is equipped with e-learning resources to build stronger conceptual foundation to excel in CUET UG-2025 to get
               admission into Undergraduate Courses in Top Colleges of participating Universities across the country. </p> 
          </Col>
          <Col md="4 offset-md-1"> 
            <form onSubmit={(e)=>{e.preventDefault(); login({'eacode':accessCode})}}>
              <div className="login-section">                
                  <div className="user">
                    {/* <i className="fa fa-user"></i> */}
                    <img src={userIcon} />
                  </div>
                    {error.status &&
                      <Alert severity="error" id="alertmsg">
                          {error.msg}
                      </Alert>
                    }
                    <div className="input-container">
                      <i className="fa fa-user icon"></i>
                      <input className="input-field" autoComplete="off" required name="UserName"  type="password" 
                      placeholder="Enter Access Code" onChange={(e)=>{setaccessCode(e.target.value)}}/>
                      {/* <input className="input-field" placeholder="Enter Your Access Code" type="text" name="name" /> */}
                      {loader && 
                        <div className="loader">
                          <div className="box">
                            <div className="circle"></div>
                          </div>
                          <div className="box">
                            <div className="circle"></div>
                          </div>
                        </div>      
                      }
                    </div>
                  <button type="submit" className="login-btn"> LOGIN </button>
                  {/* <div><Button className="login-btn"> Login </Button></div> */}
                  <div><Button className="register-btn"> <Link to="/signup"> Register Now </Link>  </Button></div>                  
                </div> 
            </form>  
          </Col>          
        </Row>
      </Container>
    </div>    
    <div className="botton-banner"> “Learning Today, Leading Tomorrow.” </div>    
    </>
  );
}
export default Login;
